import { SessionProvider } from "next-auth/react"
import { AppProps } from 'next/app';
import Head from 'next/head';
import './../styles.css';
import '@fontsource/barlow/300.css'
import '@fontsource/barlow/400.css'
import '@fontsource/barlow/600.css'

function CustomApp({ Component, pageProps, }: AppProps) {
  return (
    <>
      <Head>
        <title>Digando Admin</title>

        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      </Head>
      <SessionProvider session={pageProps.session}>
        <Component {...pageProps} />
      </SessionProvider>
    </>
  );
}

export default CustomApp;
